.content_detail{
  margin: 0 0 35px 0!important;
}

.confirm_form{
  .time-item{
    margin: 18px 0 0 0;
    max-width: 248px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2E2E30;
    font-weight: normal;
    font-size: 14px;
    gap: 5px;
    .time{
      color: #E5001C;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .submit_confirm{
    width: 248px;
    padding: 20px 94px;
    margin: 24px 0 0 0;
  }
}