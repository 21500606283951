.container {
  display: flex;
  gap: 24px;
}

.block {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #5D5B5B;
  margin-bottom: 8px;
}

.admin_picker{
  border: 1px solid #DCDCDC;
  border-radius: 10px;
  height: 44px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 12px;

  input {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #5D5B5B;

    &::placeholder {
      color: #9B9B9B;
    }
  }
}