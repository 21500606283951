.container {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  height: calc(100% - 80px);
}

.links {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #DCDCDC;
  margin-bottom: 24px;
}

.links button, .links a {
  border-radius: 20px;
  height: 34px;
  padding: 0 24px;
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.links button {
  color: #fff;
  background-color: #E5001C;
}

.content {
  display: flex;
  gap: 48px;
}

.leftPart {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.rightPart {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 448px;
}

.rightTopPart {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid #DCDCDC;
  padding: 24px;
}

.repeatOnBlock {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.repeatOnBlock span {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #5D5B5B;
}

.repeatOnDays {
  display: flex;
  gap: 12px;
}

.repeatOnDays button {
  display: flex;
  width: 36px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 18px;
}

.nonactiveDay {
  background-color: #E6E6E6;
  color: #5D5B5B;
}

.activeDay {
  background-color: #E5001C;
  color: #fff;
}

.actions {
  display: flex;
  gap: 24px;
  align-items: center;
}

.submit {
  display: flex;
  padding: 13px 46px;
  height: 43px;
  background-color: #E5001C;
  border-radius: 23px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  cursor: pointer;

  &:disabled {
    background-color: grey;
  }
}

.codeBlock {
  display: flex;
  padding: 8px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 2px dashed #E5001C;
  background: #FFF;
}

.code {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.action {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}

.action span {
  color: #E5001C;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}