.dashboard_content-inner{
  display: flex;
  flex-direction: column;
  gap: 28px;
  .dashboard_stats-list{
    display: flex;
    gap: 35px;
    align-items: center;
    justify-content: space-between;
  }
  .dashboard_charts-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 28px;
    column-gap: 16px;
    .chart_item{
      max-height: 100%;
      height: 450px;
      //max-width: 762px;
      padding: 15px 15px 15px 15px;
      width: 100%;
      //height: 352px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 21px #0000001A;
      border-radius: 10px;
      .chart_item_wrapper{
        height: 370px;
        &.rating{
          .rating_inner_row{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .row_chart{
              width: 242px;
              height: 242px;
            }
            .row_line_chart{
              width: 308px;
              height: 100%;
            }
          }
        }
      }
      .chart_item_head{
        padding: 0 15px 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .chart_title{
          font-weight: bold;
          font-size: 18px;
          color: #363940;
        }
        .chart_action{
          width: 100%;
          &.action_total{
            max-width: 92px;
          }
          &.action_customer{
            max-width: 87px;
          }
        }
      }
    }
  }
}