.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 24px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 24px;
    border-radius: 23px;
    border: none;
    color: #FFFFFF;
    background-color: #E5001C;
    height: 43px;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
  }
}