.coupon_overlay{
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 20%) 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  display: flex;
  align-items: center;
  justify-content: center;
  .coupon_wrapper{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 16px 16px 30px 16px;
    min-width: 308px;
    flex-direction: column;
    display: flex;
    gap: 20px;
    .wrapper_head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2{
        font-size: 18px;
        font-weight: 400;
        color: #2E2E30;
      }
      svg{
        cursor: pointer;
      }
    }
    .wrapper_content{
      flex-direction: column;
      display: flex;
      gap: 20px;
      .wrapper_search_field{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #9B9B9B;
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 12px 14px;
        border-radius: 30px;
        input{
          &::placeholder{
            font: normal normal normal 14px/17px Rubik;
            letter-spacing: 0px;
            color: #9B9B9B;
          }
        }
      }
      .coupon_form{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .coupon_list{
          display: flex;
          flex-direction: column;
          gap: 18px;
          li{
            font-weight: 500;
            font-size: 16px;
            color: #5D5B5B;
          }
        }
        input[type=submit]{
          background: #E5001C 0% 0% no-repeat padding-box;
          border-radius: 35px;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
          text-transform: uppercase;
          width: 100%;
          height: 43px;
        }
      }
    }
  }
}