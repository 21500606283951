.users_list_wrapper{
  max-width: 436px;
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 9px;
  height: 100%;

  .users_selected_list{
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 0 0 10px 0;
    .list_item{
      cursor: pointer;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      padding: 0 0 10px 0;
      border-bottom: 3px solid transparent;
      &.selected {
        border-bottom: 3px solid #E5001C;
      }
    }
  }

  .users_list{
    background: #F9F9F9;
    border-radius: 22px;
    width: 100%;
    padding: 15px 15px 35px 15px;
    height: calc(100% - 54px);
    .list_head{
      margin: 0 0 40px 0;
      .search_wrapper{
        .search_field{
          height: 60px;
          border-radius: 209px;
          .input_search{
            &::placeholder{
              color: #9B9B9B;
            }
          }
        }
      }
    }
    .list_colum{
      flex-direction: column;
      display: flex;
      gap: 31px;
      overflow-y: auto;
      height: calc(100% - 80px);

      li{
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 9px 10px;
        &.selected_user{
          background: #FFFFFF 0% 0% no-repeat padding-box;
          box-shadow: 0px 4px 7px #0000000F;
          border-radius: 209px;
        }
        img{
          width: 41px;
          height: 41px;
          overflow: hidden;
          border-radius: 50%;
        }
        h5{
          margin: 0;
          font-weight: normal;
          font-size: 16px;
          color: #5D5B5B;
        }
      }
    }
  }
}
