input[type="checkbox"].ios8-switch {
  position: absolute;
  margin: 8px 0 0 16px;
  visibility: hidden;

}
input[type="checkbox"].ios8-switch + label {
  position: relative;
  padding: 5px 0 0 50px;
  line-height: 2.0em;
}
input[type="checkbox"].ios8-switch + label:before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 35px; /* x*5 */
  height: 22px; /* x*3 */
  border-radius: 16px; /* x*2 */
  background: #fff;
  border: 1px solid #d9d9d9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
input[type="checkbox"].ios8-switch + label:after {
  content: "";
  position: absolute;
  display: block;
  left: 3px;
  top: 50%;
  transform: translate(0, -50%);
  width: 16px;
  height: 16px;
  border-radius: 16px; /* x*2 */
  background: #E5001C;
  border: 1px solid #B0B0B0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input[type="checkbox"].ios8-switch:checked + label:after {
  margin-left: 16px;
  background: green;
  left: 0;
}
input[type="checkbox"].ios8-switch:checked + label:before {
  //background: #55D069;
}

label{
  font-weight: 400;
  font-size: 14px;
  color: #5D5B5B;

  input, textarea {
    font-weight: 500;
    box-shadow: 0 0 0 1px #DCDCDC;
    cursor: pointer;
    transition: box-shadow .3s;

    &:hover {
      box-shadow: 0 0 0 1px #9B9B9B;
    }

    &:focus {
      box-shadow: 0 0 0 1px #5D5B5B;
    }
  }
}


