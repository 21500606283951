.container {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  height: calc(100% - 80px);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 820px;
}

.actions {
  display: flex;
  gap: 24px;
  align-items: center;
}

.submit {
  display: flex;
  padding: 13px 46px;
  height: 43px;
  background-color: #E5001C;
  border-radius: 23px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  cursor: pointer;

  &:disabled {
    background-color: grey;
  }
}

.discountBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.discountsTitle {
  color: #5D5B5B;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.discounts {
  display: grid;
  grid-template-columns: repeat(5, 110px);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.lifespan {
  width: 366px;
}

.errorText {
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #E5001C;
  gap: 4px;
  position: absolute;
  bottom: -22px;
  left: 0;
}