.employee_status{
  font-weight: bold;
  font-size: 12px;
  color: #000000;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 137px;
  padding: 6px 14px;
  width: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
}