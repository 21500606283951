.wrap{
  padding: 24px;
  background: #FFFFFF;
  position: relative;
  height: calc( 100% - 80px);
  border-radius: 12px;

  .wrap_head{
    display: flex;
    justify-content: flex-end;
    margin: 0 0 20px 0;
  }
  table{
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;
    .selection_td{
      width: 50px;
    }
    thead{
      color: #2E2E30;
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;

      tr{
        th{
          text-align: start;

          .head-row{
            display: flex;
            align-items: center;
            gap: 6px;
          }

          .head-row-center {
            justify-content: center;
          }
        }
      }
    }
    .table_loader{
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    tbody{
      .selected_checkbox{
        cursor: pointer;
        svg{
          visibility: hidden;

          width: 15px;
          height: 15px;
        }
        position: relative;
        &:before{
          position: absolute;
          content: '';
          width: 16px;
          height: 16px;
          border: 1px solid #2C3E50;
          border-radius: 2px;
        }
        input{
          margin: 0;
          visibility: hidden;
        }
      }
      tr{
        font-weight: 400;
        font-size: 16px;
        color: #2E2E30;
        border-bottom: 1px solid #CCCCCC;
        align-items: center;
        &:first-child{
          border-top: 1px solid transparent;
        }
        td{
          padding: 11px 0;
        }
        &.selected_row{
          color: #2E2E30;
          td{
            .selected_checkbox{
              svg{
                visibility: visible;

              }
            }
          }
        }
      }

    }
  }

}