.time_picker{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #DCDCDC;
  border-radius: 10px;
  padding: 10px 12px;
  width: 100%;
  gap: 4px;

  input{
    color: #5D5B5B;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.59px;
    width: 100%;
  }
  img{
    cursor: pointer;
  }
  svg{
    min-width: 24px;
    height: 24px;
  }
  &.opened{
    .schevron_svg{
      transform: rotate(180deg);
    }
  }
}

.time_wrapper{
  position: relative;
  .time_label{
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.59px;
    text-align: left;
    color: #5D5B5B;
    margin: 0;
    position: absolute;
    top: -24px;
  }
}
//border: 1px solid #DCDCDC;
.picker_popper{
  width: 100%;
    .react-datepicker{
      border: 1px solid #DCDCDC;
      border-radius: 4px;
      overflow: hidden;
      width: 100%;
      .react-datepicker__time-container {
        border: none;
        width: 100%;
        .react-datepicker__header{
          display: none;
        }
        .react-datepicker__time{
          .react-datepicker__time-box{
            width: 100%;
            padding: 8px 0;
            .react-datepicker__time-list{
              width: 100%;
              .react-datepicker__time-list-item{
                padding: 10px 8px 10px 16px;
                width: 100%;
                color: #5D5B5B;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.59px;
                text-align: left;
                &:hover{
                  color: #E5001C;
                  background: #fff;
                }
                &.react-datepicker__time-list-item--selected{
                  background-color: transparent;
                }
              }
            }

          }
        }
      }
    }
}