.sms_wrap{
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  .display{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7E7E7E;
    border-radius: 5px;
    width: 54px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    .shadows {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      box-shadow: inset 0px 0px 4px 4px rgb(118 118 118 / 28%);
    }
  }
  .input {
    position: absolute;
    border: none;
    font-size: 32px;
    text-align: center;
    background-color: transparent;
    outline: none;
  }
}