.stats_item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 358px;
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0 13px 21px #0000001A;
  border-radius: 10px;
  overflow: hidden;
  padding: 25px 30px 25px 40px;
  .stats_item-details{
    .details_title{
      margin: 0 0 13px 0;
      font-weight: 500;
      font-size: 16px;
      color: #9B9B9B;
    }
    .details_count{
      font-weight: bold;
      font-size: 40px;
      color: #000000;
    }
  }
  .item_count{
    width: 70px;
    height: 70px;
    background: #FFDB00 0% 0% no-repeat padding-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}