/* Выставляем основные настройки по-умолчанию для body */
body {
  height: 100vh;
  text-rendering: optimizeSpeed;
  scroll-behavior: smooth;
  overflow: hidden;
  background: rgba(230, 230, 230, 0.26) 0% 0% no-repeat padding-box;
  font-family: 'Rubik', sans-serif;
}
body.overflow{
  overflow: hidden;
}
#admin{
  height: 100%;
}

.admin_btn{
  background: rgba(229, 0, 28, 1) 0% 0% no-repeat padding-box;
  border-radius: 30px;
  overflow: hidden;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  min-width: fit-content;
}

.loans_btn{
  background: #518CEF;
  box-shadow: 8.28741px 18.9426px 50.9084px rgba(0, 0, 0, 0.14);
  border-radius: 48.9238px;
  height: 68px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;

  font-weight: 800;
  font-size: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.loans_container{
  max-width: 1512px;
  margin: 0 auto;
}
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -ms-overflow-style: none;  /* IE and Edge */
}

*::-webkit-scrollbar {
   width: 8px;
 }

/* Track */
*::-webkit-scrollbar-track {
   background: #F4F4F4;
   border-radius: 6px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
   background-color: #DCDCDC;
   border-radius: 6px;
   border: 1px solid transparent;
   background-clip: content-box;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
   background-color: #d4d4d4;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
  padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}




th{
  font-weight: inherit;
}


/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
  list-style: none;
  padding: 0;
  margin: 0;
}

li{
  list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}
a{
  text-decoration: none;
}

/* Упрощаем работу с изображениями */
img {
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
  outline: none;
  border: none;
}
button{
  outline: none;
  border: none;
  cursor: pointer;
}



/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}















