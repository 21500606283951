.container {
  display: flex;
  align-items: center;
  padding: 13px 46px;
  height: 43px;
  border-radius: 23px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  cursor: pointer;
  text-transform: uppercase;

  &:disabled {
    background-color: grey;
  }
}

.withoutBorder {
  background-color: #E5001C;
  color: #FFFFFF;
}

.withBorder {
  background-color: initial;
  color: #E5001C;
  border: 2px solid #E5001C;
}