.app_header{
  margin-left: 240px;
  padding: 24px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  .app_location{
    background-color: #FFF7F8;
    border-radius: 22px;
    height: 48px;
    padding: 14px 16px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    line-height: 19px;
    .location_ico {
      height: 20px;
    }
    .arrow_ico{
      margin: 0 0 0 7px;
      width: 9px;
      height: 9px;
    }
    &:hover{
      border: 1px solid rgba(207, 44, 43, 1);
    }
  }
}