.section {
  background: #FFFFFF;
  border-radius: 12px;

  :global {
    .tab-list {
      padding: 24px 24px 8px 24px;
    }
  }
}

.availability {
  display: flex;
  gap: 8px;
  align-items: center;
}

.centered {
  text-align: center;
}

.assign {
  cursor: pointer;
  color: #E5001C;
  text-decoration: underline;
  white-space: nowrap;
}

.actions {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
}

.change {
  border: 0.75px solid #1900FF;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
  }
}