.sold_items_wrapper{
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  padding: 26px 20px 60px 60px;
  .wrapper_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3{
      font-size: 14px;
      font-weight: 500;
      color: #5D5B5B;
      margin: 0 0 8px 0;
    }
  }

  .sold_items_list{
    display: flex;
    align-items: center;
    gap: 41px;
    margin: 0 0 60px 0;
  }
  .wrap{
    padding: 0;
    table{
      thead{
        font-size: 12px;
        color: #2E2E30;
      }
      tbody{
        tr{
          color: #5D5B5B;
        }
      }
    }
  }
}