.btn_export{
  background: #E5001C 0% 0% no-repeat padding-box;
  border: 1px solid #9B9B9B;
  border-radius: 25px;
  width: 170px;
  height: 43px;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  img{
    width: 22px;
    height: 22px;
  }
}