.drop_container{
  width: 100%;
  color: #999999;
  position: relative;
  font-size: 10px;
  font-weight: normal;
  cursor: pointer;
  .drop_head{
    border: 1px solid rgb(155, 155, 155, 0.2);
    border-radius: 10px;
    padding: 9px 14px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: transform 0.2s linear;
    &.head_open{
      svg{
        transition: transform 0.3s linear;
        transform: rotate(180deg);
      }
    }
    svg{
      width: 10px;
      fill: #999999;
    }
  }
  .drop_content{
    width: 100%;
    left: 0;
    position: absolute;
    z-index: 10;
    background: #FFFFFF;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 10px;
    .drop_item{
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      &.selected{
        &:before{
          position: absolute;
          right: 0;
          content: '';
          background: #FFDB00;
          width: 5px;
          height: 5px;
          border-radius: 50%;
        }
      }
    }
  }
}