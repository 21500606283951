.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.container {
  outline: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  width: 676px;
  //height: 610px;
  background-color: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #dad6d3;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
