.filter_accordion{
  .accordion_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .accordion_title{
      font-size: 16px;
      font-weight: 500;
      line-height: 18.96px;
      color: #2E2E2E;
    }
    .chevron{
      &.chevron_open{
        transform: rotate(180deg);
      }
    }
  }
  .accordion_content{
    height: 0;
    overflow-y: hidden;

    &.accordion_content_opened{
      height: fit-content;
      max-height: 280px;
      overflow-y: auto;
      padding: 16px 0 0 0;
    }
  }
}