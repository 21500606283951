.calendar_overlay{
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0 0 0 / 22%);
  .modal_wrapper{
    background: #FFFFFF;
    padding: 26px 22px 40px 40px;
    width: 676px;
    height: 496px;
    border-radius: 10px;
    .modal_head{
      margin: 0 0 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h4{
        font-size: 24px;
        color: #000000;
      }
      .modal_close{
        img{
          width: 30px;
          height: 30px;
        }
      }
    }
    .date_row{
      display: flex;
      align-items: center;
      gap: 15px;
      svg{
        fill: #E5001C;
        width: 15px;
      }
    }
    .row_fields {
      display: flex;
      column-gap: 54px;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 48px;
      margin: 58px 0 0 0;
      .time_wrapper{
        max-width: 274px;
        width: 100%;
      }
      .checkbox_wrapper{
        max-width: 274px;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
    .modal_action{
      display: flex;
      justify-content: center;
      margin: 100px 0 0 0;
      .submit_modal{
        cursor: pointer;
        width: 156px;
        height: 50px;
        background: #E5001C;
        border-radius: 35px;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        text-transform: uppercase;
      }
    }
  }
}