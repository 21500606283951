.selected_checkbox{
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #5D5B5B;
  .check_view{
    position: relative;
    min-width: 20px;
    width: 20px;
    height: 20px;
    &:before{
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 2px solid #DCDCDC;
      background: #FFFFFF;
      position: absolute;
      content: '';
      left: 0;
    }
    input{
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      visibility: hidden;
      z-index: 2;
    }
    svg{
      visibility: hidden;
      z-index: 1;
    }
  }
  &.checked{
    svg{
      visibility: visible;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .check_view{
      &:before{
        border: 2px solid #E5001C;
        background: #E5001C;
      }
    }
  }
}