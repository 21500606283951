.tabs{
  .tab-list {
    gap: 48px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    width: calc(100vw - 288px);
    white-space: nowrap;

    &::-webkit-scrollbar {
      height: 9px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      margin: 0 24px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      transform: translateY(50%);
    }


    //margin: 0 0 40px 0!important;
    .tab-list-item {
      font-weight: 500;
      font-size: 14px;
      color: #5D5B5B;
      width: max-content;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: padding .3s;
      &.tab-list-active {
        color: #FFFFFF;
        background: #E5001C;
        height: 34px;
        border-radius: 30px;
        padding: 8px 26px;
      }
    }
  }
  .tab-content{

  }
}




