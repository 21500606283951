.section {
  background: #FFFFFF;
  border-radius: 12px;

  :global {
    .tab-list {
      padding: 24px 24px 8px 24px;
    }
  }
}

.nameBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #5D5B5B;

  img {
    height: 42px;
    border-radius: 50%;
  }
}

.actionsRow {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  gap: 16px;
}

.action {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
  }
}

.actionChange {
  border: 0.75px solid #1900FF;
}

.actionDelete {
  border: 0.75px solid red;
}

.availability {
  display: flex;
  gap: 8px;
  align-items: center;
}

.centered {
  text-align: center;
}

.item {
  grid-template-columns: 1fr 1fr auto;

  .firstCol {
    display: flex;
  }
}