.container {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  height: calc(100% - 80px);
}

.links {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #DCDCDC;
  margin-bottom: 24px;
}

.links button, .links a {
  border-radius: 20px;
  height: 34px;
  padding: 0 24px;
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.links button {
  color: #fff;
  background-color: #E5001C;
}

.content {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}


.submit {
  display: flex;
  padding: 13px 46px;
  height: 43px;
  background-color: #E5001C;
  border-radius: 23px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  cursor: pointer;

  &:disabled {
    background-color: grey;
  }
}

