.timesheet_wrapper{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 24px;
  height: calc(100% - 80px);
  .timesheet_inner_row{
    display: flex;
    gap: 50px;
    height: 100%;
    //.row_employees_list{
    //  background: #F9F9F9;
    //  border-radius: 22px;
    //  padding: 15px 15px 35px 15px;
    //  max-width: 436px;
    //  width: 100%;
    //  .list_head{
    //    margin: 0 0 40px 0;
    //    .search_wrapper{
    //      .search_field{
    //        height: 60px;
    //        border-radius: 209px;
    //        .input_search{
    //          &::placeholder{
    //            color: #9B9B9B;
    //          }
    //        }
    //      }
    //    }
    //  }
    //  .list_colum{
    //    flex-direction: column;
    //    display: flex;
    //    gap: 31px;
    //    li{
    //      cursor: pointer;
    //      display: flex;
    //      align-items: center;
    //      gap: 20px;
    //      padding: 9px 10px;
    //      &.selected_user{
    //        background: #FFFFFF 0% 0% no-repeat padding-box;
    //        box-shadow: 0px 4px 7px #0000000F;
    //        border-radius: 209px;
    //      }
    //      img{
    //        width: 41px;
    //        height: 41px;
    //        overflow: hidden;
    //        border-radius: 50%;
    //      }
    //      h5{
    //        margin: 0;
    //        font-weight: normal;
    //        font-size: 16px;
    //        color: #5D5B5B;
    //      }
    //    }
    //  }
    //}
    .row_employees_details{
      .list_details{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 30px ;
        row-gap: 16px;
        margin: 0 0 16px 0;
        .detail_item{
          min-width: 230px;
          padding: 18px 17px;
          background: #FFFFFF;
          box-shadow: 0 13px 21px #0000001A;
          border-radius: 10px;
          display: flex;
          align-items: center;
          gap: 18px;
          img{
            width: 68px;
            height: 68px;
            border-radius: 50%;
          }
          .item_content{
            flex-direction: column;
            gap: 6px;
            display: flex;
            font-weight: 500;
            h3{
              margin: 0;
              font-size: 38px;
              color: #000000;
            }
            p{
              font-size: 16px;
              color: #7E7E7E;
            }
            &.user_details{
              font-weight: normal;
              h3{
                font-size: 18px;
              }
              p{
                color: #808080;
                font-size: 14px;
              }
            }
          }
        }
      }
      .row_action{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0 0 6px 0;
        .date_pick_action{
          display: flex;
          align-items: center;
          gap: 16px;
          .admin_picker{
          }
        }
      }
      .table_sheets{
        max-width: 953px;
        width: 100%;
        height: calc(100% - 172px);
        overflow: auto;

        .wrap{
          padding: 0;
          height: 100%;
          table{
            thead{
              tr{
                th{
                  color: #5D5B5B;
                }
              }
            }
            tbody{
              tr{
                td{
                  color: #525252;
                  padding: 12px 0 12px 0;
                }
              }
            }
          }
        }
      }
    }
  }
}