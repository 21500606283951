.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: #5D5B5B;
  position: relative;
}

.above {
  z-index: 3;
}

.afterText {
  font-size: 14px;
  line-height: 17px;
  color: #9B9B9B;
}

.full {
  width: 100%;
}

.md {
  width: 554px;
}

.s {
  width: 200px;
}

.field_error {
  &_text {
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #E5001C;
    gap: 4px;
    position: absolute;
    bottom: -22px;
    right: 0;
  }
}