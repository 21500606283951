.customer_details{
  .details_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 65px 0 0 0;
    margin: 0 0 21px 0;
    .row_title{
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      text-transform: uppercase;
      margin: 0 0 15px 0;
    }
    .row_customer_desc{
      display: flex;
      align-items: center;
      gap: 28px;
      img{
        width: 107px;
        height: 107px;
        border-radius: 50%;
      }
      .desc_details{
        flex-direction: column;
        display: flex;
        gap: 13px;
        h3{
          font-weight: 400;
          font-size: 18px;
          color: #000000;
        }
        span{
          font-size: 14px;
          font-weight: 400;
          color: #808080;
        }
      }
    }
    .row_action{
      input{
        background: #E5001C 0% 0% no-repeat padding-box;
        border-radius: 25px;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        text-transform: uppercase;
        width: 201px;
        height: 50px;
      }
    }
    &.count_row{
      gap: 37px;
      padding: 0;
      li{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 13px 21px #0000001A;
        border-radius: 10px;
        padding: 11px 30px;
        max-width: 210px;
        width: 100%;
        .count{
          font-size: 40px;
          font-weight: bold;
          color: #000000;
          margin: 0 0 8px 0;
        }
        .title_count{
          font-weight: 500;
          font-size: 16px;
          color: #9B9B9B;
        }
      }
    }
    &.column_row{
      flex-direction: column;
      align-items: start;
      margin: 0;
      .address_row{
        display: flex;
        align-items: center;
        gap: 52px;
        width: 100%;
        li{
          background: #FFFFFF 0% 0% no-repeat padding-box;
          border: 1px solid #D4D4D4;
          border-radius: 10px;
          //padding: 25px 15px 30px 15px;
          width: 100%;
          .address_head{
            text-transform: uppercase;
            padding: 25px 28px 15px 28px;
            border-bottom: 1px solid #E5E5E5;
          }
          .address_body{
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 30px 28px 32px 28px;
            h3{
              font-weight: 500;
              font-size: 16px;
              color: #000000;
            }
            p{
              font-weight: 400;
              font-size: 16px;
              color: #5D5B5B;
            }
          }
        }
      }
      .past_orders{
        width: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #D4D4D4;
        border-radius: 10px;
        table{
          width: 100%;
          border-spacing: 0;
          border-collapse: collapse;
          display: flex;
          flex-flow: column;
          height: 322px;

          thead{
            tr{
              width: 100%;
              display: table;
              table-layout: fixed;
              th{
                padding: 28px 0 0 28px;
                font-size: 14px;
                font-weight: 400;
                color: #9B9B9B;
                text-align: start;
              }
            }
          }
          tbody{
            flex: 1 1 auto;
            display: block;
            overflow-y: auto;
            overflow-x: hidden;
            tr{
              width: 100%;
              display: table;
              table-layout: fixed;
              border-bottom: 1px solid #E5E5E5;
              td{
                padding: 36px 28px 33px 28px;
                &.status_td{
                  .status{
                    margin: 0 0 0 auto;
                    background: #E9E9E9 0% 0% no-repeat padding-box;
                    border-radius: 202px;
                    font-weight: 400;
                    font-size: 12px;
                    color: #000000;
                    max-width: 98px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 27px;
                  }
                }
              }
            }
          }
        }
      }
      .referred_members_row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 28px;
        width: 100%;
        li{
          background: #FFFFFF 0% 0% no-repeat padding-box;
          border: 1px solid #D4D4D4;
          border-radius: 10px;
          padding: 18px 26px;
          max-width: 484px;
          width: 100%;
          flex-direction: column;
          display: flex;
          gap: 15px;
          figure{
            overflow: hidden;
            width: 64px;
            height: 64px;
            border-radius: 50%;
            img{
              width: 100%;
              height: 100%;
            }
          }
          h4{
            font-weight: 500;
            font-size: 16px;
            color: #000000;
          }
          p{
            font-weight: 400;
            font-size: 16px;
            color: #5D5B5B;
          }
        }
      }
    }
  }
}