.container {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 620px;
}

.text {
  color: #2E2E30;
  font-size: 24px;
}