.modal {
  max-height: 610px;
}

.sub_title{
  font-weight: 500;
  font-size: 12px;
  color: rgb(155, 155, 155);
  margin: 5px 0 0 0;
}

.list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding-right: 44px;
}

.line {
  display: flex;
  gap: 24px;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 0;

  span {
    font-size: 14px;
    line-height: 17px;
    color: #5D5B5B;
    font-weight: 500;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.headerActions {
  display: flex;
  gap: 48px;
  align-self: flex-end;
}