.hours_wrapper{
  background: #FFFFFF;
  padding: 49px 24px;
  border-radius: 10px;
  .wrapper_inner{
    display: grid;
    gap: 32px;
    .day_row{
      display: flex;
      align-items: center;
      gap: 16px;
      .day_name{
        background: #E6E6E6;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.59px;
        border-radius: 10px;
        width: 44px;
        color: #5D5B5B;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.weekend{
          background: #FCE6E8;
          color: #8C0011;
        }
      }
      .time_field{
        max-width: 180px;
        width: 100%;
      }
    }
  }

}
