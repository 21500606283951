.custom-scrollbars__container {
  display: grid;
  grid-template: auto / 1fr 30px;
  overflow-y: auto;
  position: relative;
  height: calc(100% - 80px);
}

.custom-scrollbars__content {
  max-height: 620px;
  height: 100%;
  -ms-overflow-style: none;
  overflow: auto;
  scrollbar-width: none;
}

.custom-scrollbars__content::-webkit-scrollbar {
  display: none;
}

.custom-scrollbars__scrollbar {
  display: grid;
  gap: 1rem;
  grid-auto-flow: row;
  grid-template: auto 1fr auto / 1fr;
  padding: 1rem;
  place-items: center;
}

.custom-scrollbars__track-and-thumb {
  display: block;
  height: 100%;
  //position: relative;
  width: 16px;
}

.custom-scrollbars__track {
  background-color: #CCCCCC;
  border-radius: 12px;
  bottom: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 9px;
}

.custom-scrollbars__thumb {
  border-radius: 12px;
  background-color: #E5001C;
  position: absolute;
  width: 9px;
}
