.logout {
  position: absolute;
  bottom: 24px;
  left: 24px;
  padding: 10px 0;
  border-radius: 23px;
  border: none;
  color: #FFFFFF;
  background-color: #E5001C;
  width: calc(100% - 48px);
  height: 43px;

  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}