.container {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  height: calc(100% - 80px);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 820px;
}

.actions {
  display: flex;
  gap: 24px;
  align-items: center;
}

.submit {
  display: flex;
  padding: 13px 46px;
  height: 43px;
  background-color: #E5001C;
  border-radius: 23px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  cursor: pointer;

  &:disabled {
    background-color: grey;
  }
}

.revert {
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #E5001C;
  background-color: inherit;
}