.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  cursor: pointer;
  height: fit-content;
  font-weight: 400;
  font-size: 14px;
  color: #5D5B5B;
}

.full {
  width: 100%;
}

.middle {
   width: 150px;
}

.image {
  height: 150px;
  width: 150px;
  border-radius: 12px;
  object-fit: contain;
}
.imageUploadRow{
  display: flex;
  gap: 8px;
  align-items: center;
  span {
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #E5001C;
  }
}
.imageUpload {
  display: flex;
  gap: 38px;
  align-items: center;
  width: 100%;
}

.input {
  display: none;
}

.emptyImagePlace {
  width: 150px;
  height: 150px;
  background: #D9D9D9;
  opacity: 0.3;
  border-radius: 12px;
}