.order_status{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #9B9B9B;
  border-radius: 25px;
  max-width: 211px;
  width: 100%;
  height: 43px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font: normal normal normal 14px/17px Rubik;
  letter-spacing: 0px;
  color: #9B9B9B;
}

.order_details-content{
  padding: 0 0 0 55px;
  flex-direction: column;
  display: flex;
  gap: 50px;
  .details_row{
    display: flex;
    align-items: center;
    gap: 100px;
    border-bottom: 1px solid #E5E5E5;
    padding: 0 0 20px 0;
    .row_item{
      flex-direction: column;
      display: flex;
      gap: 10px;
      h2{
        font-weight: 400;
        font-size: 12px;
        color: #2E2E30;
      }
      span{
        font-weight: 400;
        font-size: 16px;
        color: #5D5B5B;
      }
    }
  }
  .address_details{
    display: flex;
    align-items: center;
    gap: 52px;
    .address_content{
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #D4D4D4;
      border-radius: 10px;
      //padding: 25px 15px 30px 15px;
      width: 100%;
      .address_head{
        padding: 25px 28px 15px 28px;
        border-bottom: 1px solid #E5E5E5;
        font-weight: bold;
        font-size: 18px;
        color: #000000;
        text-transform: uppercase;
      }
      .address_description{
        padding: 30px 28px 32px 28px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 30px 0 0 0;
        .name{
          font-weight: 500;
          font-size: 16px;
          color: #000000;
        }
        p{
          font-weight: 400;
          font-size: 16px;
          color: #5D5B5B;
        }
      }
    }
  }
  .order_details{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D4D4D4;
    border-radius: 10px;
    table{
      border-collapse: collapse;
      thead{
        border-bottom: 1px solid #E5E5E5;
        height: 70px;
        tr{
          th{
            &:first-child{
              padding: 0 0 0 25px;
            }
            text-align: start;
          }
        }
      }
    }
    .status_table{
      width: 70%;
      thead{
        border-bottom: 1px solid #E5E5E5;
        height: 70px;
        tr{
          th{
            &:first-child{
              padding: 0 0 0 25px;
            }
            text-align: start;
          }
        }
      }
      tbody{
        tr{
          border-bottom: 1px solid #E5E5E5;
          td{
            text-align: start;
            padding: 25px;
            &.dot_td{
              width: 12px;
              padding: 0;
              .dot{
                width: 11px;
                height: 11px;
                background: #5D5B5B 0% 0% no-repeat padding-box;
                border-radius: 50%;
              }
            }
            &:first-child{
              width: 280px;

            }

          }
        }
      }
    }
    .bill_table{
      width: 100%;
      tbody{
        tr{
          td{
            font-weight: 500;
            font-size: 16px;
            color: #5D5B5B;
            span{
              font-weight: 400;
              font-size: 14px;
              color: #8B8989;
              margin: 0 0 0 30px;
            }
          }
          &.head_tr{
            td{
              font-size: 14px;
              font-weight: 400;
              color: #8B8989;
              padding: 25px;
              &:first-child{

              }
              &.w-200-px{
                width: 200px;
              }
            }
          }
          &.border_bottom{
            border-bottom: 1px solid #E5E5E5;
          }
          &.p-15{
            td{
              padding: 15px 25px;
            }
          }
          &.tr_total{
            td{
              padding: 20px 25px;
            }
          }
        }

      }
    }
  }
}