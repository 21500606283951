.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;

  input {
    height: 44px;
  }

  input:disabled {
    border-radius: 10px;
    background: #F4F4F4;
  }

  textarea {
    resize: none;
    height: max-content;
  }

  input, textarea{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 12px;
    width: 100%;
    font-size: 14px;
    line-height: 17px;
    color: #5D5B5B;

    &.withIcon {
      padding-left: 40px;
    }

    &::placeholder {
      font-size: 14px;
      line-height: 17px;
      color: #9B9B9B;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.beforeIcon {
  position: absolute;
  top: 35px;
  left: 12px;
}

.beforeIconInLine {
  position: absolute;
  top: 13px;
  left: 60px;
}

.field_error {
  input, textarea {
    box-shadow: 0 0 0 1px #E5001C !important;
  }

  &_text {
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #E5001C;
    gap: 4px;
    position: absolute;
    bottom: -22px;
    right: 0;
  }
}

.col-2-134{
  flex: 0 1 calc((100% - 134px) / 2);
  max-width: 550px;
}

.full {
  width: 100%;
}

.sm {
  width: 258px;
}

.afterText {
  position: absolute;
  top: 36px;
  right: 12px;
}

.inLine {
  display: flex;
  flex-direction: row;
  align-items: center;
}