.container {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  height: calc(100% - 80px);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 820px;
}

.topBlock {
  display: flex;
  width: 820px;
  gap: 24px;
}

.topBlockInputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.cookTime {
  input {
    padding-right: 70px;
  }
}

.actions {
  display: flex;
  gap: 24px;
  align-items: center;
}

.submit {
  display: flex;
  padding: 13px 46px;
  height: 43px;
  background-color: #E5001C;
  border-radius: 23px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  cursor: pointer;

  &:disabled {
    background-color: grey;
  }
}

.revert {
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #E5001C;
  background-color: inherit;
}

.estimatedTime {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 256px;

  div {
    display: flex;
    gap: 8px;
  }

  input {
    height: 44px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 12px 70px 12px 12px;
    width: 118px;
    font-size: 14px;
    line-height: 17px;
    color: #5D5B5B;

    &.withIcon {
      padding-left: 28px;
    }

    &::placeholder {
      font-size: 14px;
      line-height: 17px;
      color: #9B9B9B;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.field_error {
    border: 1px solid red;
  }

  .afterText {
    position: absolute;
    top: 36px;
    left: 50px;
  }

  .afterText1 {
    position: absolute;
    top: 36px;
    right: 24px;
  }
}

.modifiers {
  margin-top: 24px;
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: min(90%, 988px);
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;

  hr {
    background: rgba(0, 0, 0, 0.1);
    border: none;
    height: 1px;
    width: 100%;
    margin: 0;
  }
}

.modifiersTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #5D5B5B;

}

.imageBlock {
  display: flex;
  gap: 24px;
}