.actionsRow {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  gap: 16px;
}

.action {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
  }
}

.nameBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #5D5B5B;

  img {
    height: 42px;
  }
}

.description {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 24px;
}

.actionChange {
  border: 0.75px solid #1900FF;
}

.actionDelete {
  border: 0.75px solid red;
}