.shift_schedule_wrapper{
  display: flex;
  gap: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 24px;
  height: calc(100% - 80px);
  .shift_schedule_calendar{
    width: 100%;
    max-height: 100%;
    overflow: auto;
    padding-right: 20px;
  }
}

.event_container{
  background: #F3F3F3!important;
  border: 1px dashed #CACACA!important;
  border-radius: 5px!important;
  padding: 9px 20px;
}

.fc-button{
  background: #E5001C!important;
  border: none!important;
}

.calendar_event{
  font-size: 10px;
  line-height: 17px;
  color: #000000;
  text-align: center;
  .event_action{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 12px 0 0 0;
    .action{
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      cursor: pointer;
      svg{
        width: 12px;
        height: 12px;
      }
      &.remove{
        border: 0.75px solid #FF0000;

      }
      &.edit{
        border: 0.75px solid #1900FF;
      }
    }
  }
}