.container {
  min-height: 44px;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;

  .searchIcon {
    position: absolute;
    left: 16px;
    z-index: 2;
  }

  input {
    color: #5D5B5B;
    padding-left: 48px;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 22px;
    font-size: 14px;
    line-height: 17px;
    box-shadow: 0 0 0 1px #DCDCDC;
    transition: box-shadow .3s;
    cursor: pointer;

    &::placeholder {
      color: #9B9B9B;
    }

    &:hover {
      box-shadow: 0 0 0 1px #9B9B9B;
      border-radius: 22px;
    }

    &:focus {
      box-shadow: 0 0 0 1px #5D5B5B;
    }
  }
}

.md {
  width: 240px;
}

.full {
   width: 100%;
}