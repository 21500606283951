.actionsRow {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  gap: 16px;
}

.action {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
  }
}

.nameBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #5D5B5B;

  img {
    height: 42px;
  }
}

.description {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 24px;
}

.filterBlock {
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    text-align: center;
  }
}

.actionChange {
  border: 0.75px solid #1900FF;
}

.actionDelete {
  border: 0.75px solid red;
}

.ingredients_row{
  display: grid;
  gap: 16px;
  grid-template-columns: auto 280px;
}

.ingredients_container{
  padding: 24px 0  ;
  gap: 24px;
  border-radius: 12px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100vh - 226px);
  .container_inner{
    padding:  0 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
}
.modifiers_list{
  display: grid;
  gap: 12px;

}
