.submit_form-login{
  max-width: 512px;
  min-width: 100%;
  width: 100%;
  .login_form_field{
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    border: 1px solid rgba(229, 229, 229, 1);
    border-radius: 30px;
    padding: 20px 24px;
    height: 60px;
    margin: 53px 0 0 0;
    display: flex;
    align-items: center;
    gap: 30px;
    .react-tel-input{
      .flag-dropdown{
        border: none!important;
        background-color: transparent!important;
        .selected-flag{
          .flag{
            .arrow{
              left: 25px!important;
            }
          }
        }
      }
    }
    input{
      width: 100%;
      border: none;
      outline: none;
      &:focus{
        border: none;
        outline: none;
      }
    }
  }
  .login_btn{
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 42px 0 0 0;
  }
}