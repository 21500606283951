.auth_layout{
  display: flex;
  height: 100vh;
  .layout_image{
    width: 58%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right bottom;
    position: relative;
    &:before{
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(46, 46, 48, 0.59);
    }
  }

  .layout_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0 148px;
    max-width: 808px;
    width: 100%;
    .layout_logo{
      margin: 0 0 165px 0;
      @media only screen and (max-width: 1440px){
        margin: 0 0 100px 0;
      }
    }
    .content_title{
      color: #2e2e30;
      font-weight: bold;
      font-size: 32px;
      margin: 0 0 16px 0;
    }
    .content_sub_title{
      font-weight: normal;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      line-height: 24px;
      .content_detail{
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 500;
        margin: 0 0 53px 0;
        img{
          cursor: pointer;
        }
      }
    }
  }
}