.draggableTableWrapper{
  background: #FFFFFF;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}
.draggableTableOverlay {
  position: relative;
  overflow: auto;
  //padding: 0 24px 24px 24px;

}

.withFiltersList {
  max-height: calc(100vh - 300px);
}

.withoutFiltersList {
  max-height: calc(100vh - 226px);
}

.draggableTable {
  width: 100%;
  border-collapse: collapse;
  padding: 24px;
}

.handleWrapper {
  height: 32px;
  vertical-align: bottom;
  display: inline-block;
  margin-right: 12px;
  width: 20px;
  min-width: 20px;
  svg {
    width: 100%;
    height: 100%;
  };
  cursor: grab;
}

.tableRow {
  display: flex;
  flex: 1 0 auto;
  min-width: 0;
  font-weight: 400;
  font-size: 16px;
  color: #2E2E30;
  border-bottom: 1px solid #CCCCCC;
  align-items: center;
}

.draggingRow {
  background: rgba(127, 207, 250, 0.3);
  height: 66px;
  display: flex;
  cursor: grabbing;
}

.tableData {
  background: white;
  display: flex;
  align-items: center;
  height: 66px;
  box-sizing: border-box;
  //min-width: max-content;
  //white-space: nowrap;
}

.styledStaticData {
  background: white;
  display: flex;
  align-items: center;
  height: 66px;
}

.styledStaticTableRow {
  box-shadow: rgb(0 0 0 / 10%) 10px 20px 25px 15px,
  rgb(0 0 0 / 30%) 0 10px 10px -5px;
  display: flex;
  align-items: center;
  background: white;
  cursor: grabbing;
  width: calc(100% + 24px) ;
  padding: 0 12px;
  margin-left: -12px;
  border-radius: 12px;
}

.thead {
  position: sticky;
  top: 0;
  z-index: 2;
  background: white;

  tr {
    padding: 8px 0;
    padding-top: 32px;
  }
}

.headTr {
  display: flex;
  flex: 1 0 auto;
}

.th {
  text-align: start;
}

.th250 {
  box-sizing: border-box;
  flex: 250 0 auto;
  width: 250px;
}

.th200 {
  box-sizing: border-box;
  flex: 200 0 auto;
  width: 200px;
}

.th150 {
  box-sizing: border-box;
  flex: 150 0 auto;
  width: 150px;
}

.th80{
  box-sizing: border-box;
  flex: 80 0 auto;
  width: 80px;
}

.thauto{
  box-sizing: border-box;
  justify-content: flex-end;
}

.flex-end {
  justify-content: flex-end;
}