.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.mainLabel {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #5D5B5B;
}

.block {
  display: flex;
  gap: 16px;
}

/* для элемента input c type="radio" */
.label>input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* для элемента label связанного с .custom-radio */
.label>span {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

/* создание в label псевдоэлемента  before со следующими стилями */
.label>span::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #AEAEB2;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

/* стили при наведении курсора на радио */
.label>input:not(:disabled):not(:checked)+span:hover::before {
  border-width: 2px;
}

/* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
.label>input:focus:not(:checked)+span::before {
  border-color: #E5001C;
}

/* стили для радиокнопки, находящейся в состоянии checked */
.label>input:checked+span::before {
  border-color: #E5001C;
  background-image: url("../../assets/images/radioButton.svg");
}

.error {
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #E5001C;
  gap: 4px;
  position: absolute;
  bottom: -22px;
  right: 0;
  font-size: 14px;
}