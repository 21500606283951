$barWidth: 240px;

.app_row{
  display: flex;
  height: calc(100% - 96px);
  .app_logo {
    margin: 24px 0 0 24px;
    width: 84px;
  };
  .app_bar{
    position: absolute;
    width: $barWidth;
    left: 0;
    top: 0;
    background: #FFFFFF;
    height: 100%;
    transition: width 0.3s;
    .bar_column{
      flex-direction: column;
      display: flex;
      gap: 18px;
      margin: 32px 24px;
      max-height: calc(100% - 240px);
      overflow-y: auto;
      overflow-x: hidden;

      li{
        a{
          position: relative;
          align-items: center;
          display: flex;
          font-weight: 500;
          font-size: 16px;
          color: #808080;

          &:hover {
            color: #E5001C;

            svg{
              .stroke_color{
                stroke: #E5001C;
              }
            }
            .active {
              color: #E5001C;
            }
          }

          div {
            display: flex;
            gap: 12px;
            align-items: center;
          }

          .arrow_down{
            position: absolute;
            left: 150px;
            stroke: #808080;
            transform: rotate(180deg);
            transition: transform .3s ease-in-out;
          }
          &.active{
            color: #2E2E2E;
            &:hover {
              color: #E5001C;
            }
            .arrow_down{
              stroke: #2E2E2E;
            }
            svg{
              .stroke_color{
                stroke: #E5001C;
              }
            }
          }
        }

        .sub_links{
          flex-direction: column;
          gap: 13px;
          display: flex;
          padding: 12px 0 0 36px;
          overflow: hidden;
          transition: max-height .3s ease-in-out;
          max-height: 0;
          a{
            font-weight: 500;
            font-size: 14px;
            color: rgba(155, 155, 155, 1);
            opacity: 1;
            &:hover {
              color: #E5001C
            }

            &.active{
              color: rgba(46, 46, 48, 1);
              position: relative;

              &:before {
                content:"\A";
                left: -14px;
                width:5px;
                height:5px;
                border-radius:50%;
                background: #E5001C;
                display:inline-block;
                position: absolute;
              }

              &:hover {
                color: #E5001C
              }
            }
          }
        }
      }
      .activeBlock {
        .sub_links {
          max-height: 210px;
        }
        .arrow_down{
          transform: rotate(0deg);
        }
      }
    }
    &.w-0{
      width: 0;
      transition: width 0.3s;
      .bar_column{
        display: none;
        padding: 0;
      }
    }
    .btn_action_bar{
      right: -32px;
      width: 32px;
      height: 80px;
      position: absolute;
      background: #9B9B9B;
      color: #FFFFFF;
      top: 300px;
      cursor: pointer;
      transform: translate(0%, -35%);
      border-radius: 0 50% 50% 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  }
  .app_content{
    margin-left: $barWidth;
    padding: 24px;
    width: 100%;
    transition: margin-left 0.3s;
    &.w-100{
      margin-left: 0;
      transition: margin-left 0.3s;
      width: 100%;
    }
    &.p-0{
      padding: 0;
    }
    .content_head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 24px 0;
      &.content_head_full{
        background: #FFFFFF;
        padding: 38px 50px;
        margin: 0 0 47px 0;
      }
      .head_inner{
        display: flex;
        align-items: center;
        gap: 15px;
      }


      .app_page-title{
        font-weight: bold;
        font-size: 48px;
        color: #000000;
      }
    }

  }
}

.head_component{
  display: flex;
  align-items: center;
  gap: 20px;
  .admin_btn{
    height: 43px;
    padding: 0 20px;
  }
}

.action_wrapper_td{
  width: 100px;
  .actions_row{
    //left: 50%;
    //top: 50%;
    //transform: translate(-50%, -50%);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    gap: 12px;
    //pointer-events: none;
    .action{
      width: 30px;
      height: 30px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg{
        width: 15px;
        height: 15px;
      }
      &.action_change{
        border: 0.75px solid #1900FF;
      }
      &.action_delete{
        border: 0.75px solid red;
      }
    }

  }
}

