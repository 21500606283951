.pagination{
  display: grid;
  grid-template-columns: auto auto 100px;
  align-items: center;
  width: fit-content;
  .limit_title{
    margin: 0 16px 0  24px;
    font-size: 14px;
    font-weight: 400;
    color: #2E2E2E;
  }
}
.pagination-container {
  display: flex;
  list-style-type: none;
  gap: 30px;
  background: #FFFFFF 0 0 no-repeat padding-box;
  border: 1px solid #FFEDEF;
  border-radius: 10px;
  justify-content: center;
  width: fit-content;
  padding: 8px;
  height: 40px;
  .pagination-item {
    color: #9B9B9B;
    font-size: 14px;
    display: flex;
    align-items: center;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      cursor: pointer;
      color: #E5001C;
      svg{
        path{
          stroke: #E5001C;
        }
      }
      &.selected{
        color: #2E2E2E;
      }
    }

    &.selected {
      color: #2E2E2E;
    }

    &.disabled {
      pointer-events: none;
      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }

  }
}
