.container {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 820px;
}

.topBlock {
  display: flex;
  width: 820px;
  gap: 24px;
}

.topBlockInputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.cookTime {
  input {
    padding-right: 70px;
  }
}

.actions {
  display: flex;
  gap: 24px;
  align-items: center;
}

.submit {
  display: flex;
  align-items: center;
  padding: 0px 46px;
  height: 43px;
  background-color: #E5001C;
  border-radius: 23px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  cursor: pointer;

  &:disabled {
    background-color: grey;
  }
}

.revert {
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #E5001C;
  background-color: inherit;
}

.estimatedTime {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 256px;

  div {
    display: flex;
    gap: 8px;
  }

  input {
    height: 44px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 12px 70px 12px 12px;
    width: 118px;
    font-size: 14px;
    line-height: 17px;
    color: #5D5B5B;

    &.withIcon {
      padding-left: 28px;
    }

    &::placeholder {
      font-size: 14px;
      line-height: 17px;
      color: #9B9B9B;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.field_error {
    border: 1px solid red;
  }

  .afterText {
    position: absolute;
    top: 38px;
    left: 50px;
  }

  .afterText1 {
    position: absolute;
    top: 38px;
    right: 24px;
  }
}

.tableOverlay {
  width: 970px;
  border-radius: 12px;
  padding: 24px;
  border: 1px solid #0000001A;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
}

.tableName {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #5D5B5B;
}

.hr {
  height: 1px;
  border: none;
  margin: 0;
  width: 100%;
  background-color: #0000001A;
}

.variationsTable {
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 100%;
}

.variationsTableHeadLine {
  display: grid;
  grid-template-columns: 300px 120px 160px 240px auto;
  width: 100%;
}

.variationsTableLine {
  display: grid;
  grid-template-columns: 300px 120px 160px 240px auto;
  height: 42px;
  align-items: center;
  width: 100%;
}

.actionsRow {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  gap: 16px;
  margin-left: auto;
}

.action {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 15px;
    height: 15px;
  }
}

.actionChange {
  border: 0.75px solid #1900FF;
}

.actionDelete {
  border: 0.75px solid red;
}

.variationTitleImage {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 42px;

  img {
    height: 100%;
  }
}

.variationsTableTitle {
  margin-left: 50px;
}

.tableHeadText {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #5D5B5B;
}

.tableText {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9B9B9B;
}

.textInRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modifiersButton {
  background-color: inherit;
}

.textInColumn {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.moreModifiersValue {
  color: #C4C4C4;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.checkbox {
  display: flex;
  gap: 24px;
  align-items: center;
}

.tooltipActions {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0px 13px 22px 0px #0000001A;
  z-index: 9;

  button {
    background-color: inherit;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #5D5B5B;
    text-align: left;
  }
}

.textEditor {
  width: 472px;

  & > :global(.ql-toolbar) {
    border-radius: 10px 10px 0 0;
  }

  & > :global(.ql-container) {
    height: auto;
    min-height: 100px;
    border-radius: 0 0 10px 10px;
  }
}

.addonsContainer {
  display: flex;
  max-width: 840px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid #DCDCDC;
}

.addonsTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}