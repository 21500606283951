.container {
  display: flex;
  gap: 8px;
  padding: 0 48px;
  margin-bottom: 24px;
}

.stepInfo {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 8px;
  width: 160px;

  img {
    width: 32px;
  }
}

.stepInfoData {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.numberOfStep {
  color: #5D5B5B;
  font-size: 10px;
}

.nameOfStep {
  color: #5D5B5B;
  font-size: 14px;
  font-weight: 500;
}

.line {
  position: absolute;
  left: 40px;
  top: 15px;
  width: 120px;
  height: 2px;
  display: flex;
}

.activePartOfLine {
  background-color: #E5001C;
  height: 100%;
  width: 50%;
}

.disabledPartOfLine {
  background-color: #DCDCDC;
  height: 100%;
  width: 50%;
}