.overlay {
  background-color: #F9F9F9;
}

.container {
  width: 398px;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
}

.nameBlock {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.name {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}